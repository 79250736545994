@import 'projects/admin/src/styles/partials/variables';

.iterra-auth {
  height: 100%;
  padding: $medium-gap;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ui-logo {
    padding-bottom: $small-gap*3;
    text-align: center;
  }

  > * {
    width: 100%;
  }
}

.not-call {
  padding: $small-gap 0;
}

.iterra-auth {
  max-width: $max-width;
  margin: 0 auto;
}
