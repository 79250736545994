@import 'projects/admin/src/styles/partials/variables';

div.header div.widget-header {
  width: 100%;
  margin: auto;
  background: transparent;
}

.playlist, .playlists, .widget-list {
  .float-panel__header {
    padding: 0 !important;
    background-color: $color-of-content !important;
  }

  .float-panel__content {
    padding: 0 !important;
  }
}

.widget-tile {

  &--square > div {
    width: 152px !important;
    height: 178px !important;
  }

  &--rectangle > div {
    width: 100% !important;
    height: 100px !important;
  }

}


