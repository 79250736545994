.notifications {
  height: 100%;
  overflow: auto;
}

.toast {
  max-width: 100%;
}

.bg-dark.bs4-toast {
  color: #fff;
  background-color: rgba(37, 40, 46, 0.9) !important;
}

.bs4-toast[class^=bg-], .bs4-toast[class*=' bg-'] {
  border: none;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  color: #474d58;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.06);
  box-shadow: 0 1px 15px rgb(0 0 0 / 20%);
  opacity: 0;
  border-radius: 0.25rem;
}

.bg-dark.bs4-toast .toast-header {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.toast-header {
  background: #8c8e91;
  border-color: #2f3238;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #474d58;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  background: #2f3238;
  padding: 0.75rem;
}

