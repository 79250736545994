@import 'projects/admin/src/styles/partials/variables';
@import 'fonts';

.emoji-mart-dark,
.emoji-mart-dark .emoji-mart-category-label span,
.emoji-mart-dark .emoji-mart-search input {
  font-family: $font-family;
  background-color: $color-of-content;
}

.emoji-mart-dark {
  width: 100% !important;
}

.emoji-mart-preview {
  display: none;
}
