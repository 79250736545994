@import 'projects/admin/src/styles/partials/variables';

.navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: $normal-gap;
  background-color: $color-of-content;

  a {
    padding: 0;
    color: $color-main;
  }

  svg {
    fill: $color-inactive-link;
  }

  &__title {
    padding: 0 $normal-gap;
    color: $color-main;
    font-size: $font-size-normal;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__link {
    line-height: 1.54;
    padding: 0;

    i {
      font-size: 120%;
      vertical-align: middle
    }

    &:hover, &:focus {
      text-decoration: none;
      outline: none;
    }

    & > [class*=' ion-']::before {
      vertical-align: baseline;
      line-height: .8695em;
      color: $color-main;
    }

    &--active {
      svg {
        fill: $color-main;
      }
    }
  }

  &__nav {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: auto;
    list-style: none;

    .ion::before, svg {
      width: 32px;
      height: 32px;
    }
  }

}

[class^='ion-']::before, [class*=' ion-']::before {
  font-size: 1.8em;
}

.guest {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.navbar__link--active {
  position: relative;

  span.connect {
    display: block;
    position: absolute;
    right: -1px;
    top: -1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #06f355;
  }
}
