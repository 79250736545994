@import 'projects/admin/src/styles/partials/variables';

.sidenav {
    display: flex;
    background: transparent;
}

.sidenav-item .sidenav-link {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
}

.sidenav-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: $normal-gap;
    font-size: 1rem;
}

.sidenav-vertical .sidenav-item .sidenav-link,
.sidenav-vertical .sidenav-header,
.sidenav-vertical .sidenav-block {
    padding: 0.75rem 2rem;
}

.sidenav-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding-top: $big-gap*2;
}

.sidenav-item {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidenav-vertical {
    width: 15.625rem;
    overflow: hidden;
    flex-direction: column;
    color: $color-tone_content_light;

    .sidenav-inner > .sidenav-item {
        margin: 0.0625rem 0;
    }

    .sidenav-icon {
        width: 1.75rem;
        font-size: 100%;
    }
}

.sidenav-vertical,
.sidenav-vertical .sidenav-block,
.sidenav-vertical .sidenav-inner > .sidenav-item,
.sidenav-vertical .sidenav-inner > .sidenav-header {
    width: 15.625rem;
}

.layout-sidenav {

    .layout-expanded & {
        transform: translate3d(0, 0, 0);
        transition: transform .2s ease-in-out;
    }

    .sidenav-vertical {
        align-items: flex-start;
        height: 100%;
    }
}

sidenav-router-link {
    font-size: $font-size-small;

    svg {
        margin-right: $normal-gap;
    }
}

.active {
    width: 100%;
    background-color: $color-primary;
}

@media (max-width: 768px) {
    .layout-sidenav-100vh .layout-sidenav, .layout-sidenav-100vh .layout-overlay {
        height: 100vh;
    }
}

@media (max-width: 768px) {
    .layout-sidenav-100vh .layout-sidenav, .layout-sidenav-100vh .layout-overlay {
        height: 100vh;
    }
}

@media (max-width: 768px) {
    .layout-sidenav {
        z-index: 1100;
    }

    .layout-sidenav {
        position: fixed !important;
        top: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        transform: translate3d(-100%, 0, 0);
        will-change: transform, -webkit-transform;
        flex: 1 0 auto;
        background-color: $color-of-content;
    }
}
