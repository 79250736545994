@import 'projects/admin/src/styles/partials/variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, .5);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: $big-gap;
  margin: 0 $medium-gap;
  text-align: center;

  img {
    padding-bottom: $big-gap;
  }

  &__title {
    padding-bottom: $normal-gap;
    font-size: $font-size-big;
    font-weight: 600;
    color: $color-main;
  }

  &__text {
    padding-bottom: $big-gap;
    font-size: $font-size-normal;
    font-weight: normal;
  }

  .btn {
    font-size: $font-size-big;
    font-weight: 600;
    width: 100%;
    padding: 0.7rem 1.125rem;
  }

  .btn + .btn {
    margin-top: $medium-gap;
  }

  .btn-danger {
    background-color: $color-alarm;
    border-color: $color-alarm;
  }
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}


.modal-dialog {
  position: relative;
  width: 100%;
  margin: $modal-dialog-margin;
  pointer-events: none;

  .modal.fade & {
    transform: $modal-fade-transform;
  }

  .modal.show & {
    transform: $modal-show-transform;
  }

  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

.modal-dialog-scrollable {
  display: flex;

  .modal-content {
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;

  &::before {
    display: block;
    height: min-content;
    content: '';
  }

  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $color-of-content;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  outline: 0;
  padding: $small-gap;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  width: 100vw;
  height: 100vh;
  background-color: $modal-backdrop-bg;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: $modal-backdrop-opacity;
  }
}

.modal-header {
  display: none !important;
  //display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;

  .close {
    padding: $modal-header-padding;
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: $normal-gap;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  > * {
    margin: $small-gap;
  }

  button {
    flex: 1;
    min-width: 200px;
  }

}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
