//font sizes
$font-size-large: 1.777rem; //32px
$font-size-increased: 1.333rem; //24px
$font-size-big: 1rem; //18px
$font-size-normal: 0.888rem; //16px
$font-size-small: 0.777rem; //14px
$font-size-mini: 0.666rem; //12px
$font-size-micro: 0.5555rem; //10px

//colors
$color-invalid: #f43a3a;
$color-valid: #02bc77;

$color-main: #ffffff;
$color-basic: #000000;

$color-of-content: #2f3238;
$color-content: #52565f;

$color-content-style: #25282e;
$color-tone_content_light: #8c8e91;
$color-tone_content_1: rgba(255, 255, 255, 0.1);
$color-tone_content_05: rgba(255, 255, 255, 0.05);

$color-primary: #236eff;

$color-secondary: #8897aa;
$color-secondary-light: #8e8e8e;
$color-secondary-tone-75: #536175;

$color-calm: #a8a9ab;
$color-warning: #ffd950;

$color-active: #2cb573;
$color-inactive-link: #52565f;

$color-alarm: #f43a3a;
$color-accent-style: #25282e;

//gaps
$big-gap: 24px;
$medium-gap: 16px;
$normal-gap: 12px;
$small-gap: 8px;

//icons
$icon-upload-size: 96px;

//border-radius
$br-medium: 3px;
$br-big: 5px;

//sizes
$footer-height: 53px;
$footer-height-ios: 80px;
$navbar-height: 53px;
$nav-top-height: 48px;

$location-tool-size: 65px;
$tabs-height: 60px;
$pull-height: 30px;

$audio-player-height: 90px;
$loading-height: 24px;

//z-index
$zindex-notification: 999999 !default;
$zindex-modal: 1055 !default;

$max-width: 1024px;

//modal
$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
$box-shadow: 0 .5rem 1rem rgba($color-of-content, .15) !default;

$box-shadow-sm: 0 .125rem .25rem rgba($color-of-content, .075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($color-of-content, .175) !default;
$modal-inner-padding: $small-gap !default;

$modal-footer-margin-between: .5rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: 1.5 !default;

$modal-content-color: null !default;
$modal-content-bg: $color-main !default;
$modal-content-border-color: rgba($color-of-content, .2) !default;
$modal-content-border-width: 1px !default;
$modal-content-border-radius: $br-big !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: $color-of-content !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $color-of-content !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default;


