@import 'projects/admin/src/styles/partials/variables';

.layout-wrapper {
  margin: 0 auto;
}

.layout-wrapper, .layout-container {
  height: 100%;
}

.layout-wrapper, .layout-inner {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  width: 100%;
}

.layout-container {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 0;
  flex-basis: 100%;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
}

.layout-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100% !important;
  left: 0;
  display: none;
  background: $color-content-style;
  opacity: 0.5;
  cursor: pointer;

  .layout-expanded & {
    display: block;
  }
}

.layout-expanded body {
  overflow: hidden;
}

.layout-collapsed.layout-offcanvas {
  .layout-sidenav {
    transform: translateX(-100%);
  }

  &.layout-reversed .layout-sidenav {
    margin-right: 0;
    transform: translateX(100%);
  }
}

.background-filter {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
  backdrop-filter: blur(8px);
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .layout-expanded .layout-overlay {
    display: block;
  }
}

@media (max-width: 768px) {
  .layout-overlay {
    z-index: 1099;
  }
}

