@use 'sass:math';
@import 'projects/admin/src/styles/partials/variables';
@import 'fonts';

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group > .form-control {
    width: 100%;
}

.invalid-feedback {
    display: block !important;
    color: $color-invalid !important;
    font-size: $font-size-small;
    font-weight: 400;
}

.ng-touched.ng-valid:not(form) {
    border-color: $color-valid;
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
}

div.form-group {
    width: 100%;
    margin-bottom: 0;
    padding: $small-gap;

    &--top-border {
        margin-top: $small-gap*3;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
    }

    &__text {
        font-size: $font-size-small;
        color: $color-calm;
    }

    &__text + &__text {
        padding-top: $medium-gap;
    }
}

.title-form-group {
    margin-bottom: $small-gap;
    font-size: $font-size-normal;
    font-weight: 600;
}

.form-control {
    display: block;
    width: 100%;
    padding: $normal-gap;
    font-size: $font-size-small;
    font-weight: 400;
    line-height: 1.54;
    color: $color-main;
    background-color: rgba(255, 255, 255, 0.05);
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.form-control {
    background: $color-of-content;
    color: $color-main;
}

option {
    padding: $normal-gap 0;
}

button, input {
    overflow: visible;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: $font-family, sans-serif;
    font-size: $font-size-normal;
    line-height: inherit;
    outline: none;
}

button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:disabled {
    filter: brightness(0.7);
}

.btn {
    height: 48px;
    display: inline-block;
    font-weight: 400;
    color: $color-main;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: $normal-gap;
    font-size: $font-size-normal;
    line-height: 1.54;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
}

.btn-primary {
    border-color: transparent;
    background: $color-primary;
    color: $color-main;
}

.btn-calm {
    background: $color-calm;
    color: $color-main;
}

.btn-alarm {
    background: $color-alarm;
    color: $color-main;
}

.btn-default {
    border-color: $color-main;
    color: $color-main;
}

.btn-secondary {
    border-color: transparent;
    background: $color-secondary;
    color: $color-main;
}

.btn-outline-secondary {
    border-color: $color-secondary;
    background: transparent;
    color: $color-main;
}

.btn-greater {
    padding-top: .67rem;
    padding-bottom: .67rem;
}

.btn-active {
    background: $color-active;
    color: $color-main;
}

.checkbox {
    $checkbox-icon-size: 24px;
    margin-bottom: $medium-gap;

    label {
        margin-bottom: 0;
        font-size: $font-size-normal;
        font-weight: normal;
    }

    input[type='checkbox'] {
        height: 0;
        width: 0;
        position: absolute;
        left: -$normal-gap;
    }

    input[type='checkbox'] + label {
        position: relative;
        display: flex;
        align-items: center;
        transition: color 250ms cubic-bezier(.4, .0, .23, 1);
    }

    input[type='checkbox'] + label > i {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $normal-gap;
        width: $checkbox-icon-size;
        height: $checkbox-icon-size;
        background: transparent;
        border: 2px solid $color-content;
        border-radius: $br-medium;
        cursor: pointer;
        transition: all 250ms cubic-bezier(.4, .0, .23, 1);
    }

    input[type='checkbox']:checked + label > i {
        border: math.div($checkbox-icon-size, 2) solid $color-primary;
        animation: shrink-bounce 200ms cubic-bezier(.4, .0, .23, 1);
    }

    input[type='checkbox']:checked + label > i:before {
        content: '';
        position: absolute;
        width: $checkbox-icon-size;
        height: $checkbox-icon-size;
        background-image: url('data:image/svg+xml;charset=UTF-8, %3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\'%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M10.4715 13.5286L16.6667 7.33337L17.6096 8.27618L10.4715 15.4143L6.66675 11.6095L7.60956 10.6667L10.4715 13.5286Z\' fill=\'white\'/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    @keyframes shrink-bounce {
        0% {
            transform: scale(1);
        }
        33% {
            transform: scale(.85);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes checkbox-check {
        0% {
            width: 0;
            height: 0;
            transform: translate3d(0, 0, 0) rotate(45deg);
        }
        33% {
            width: .2em;
            height: 0;
            transform: translate3d(0, 0, 0) rotate(45deg);
        }
        100% {
            width: .2em;
            height: .5em;
            transform: translate3d(0, -.5em, 0) rotate(45deg);
        }
    }
}

.custom-radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-radio + label {
    display: flex;
    user-select: none;
    font-size: $font-size-normal;
    font-weight: 400;
}

.custom-radio + label::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $color-content;
    border-radius: 50%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $color-tone_content_05;
}

.custom-radio:checked + label::before {
    border-color: $color-primary;
    background-color: $color-primary;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23fff\'/%3e%3c/svg%3e');
}

.custom-radio:disabled + label::before {
    background-color: #e9ecef;
}

font {
    font-size: $font-size-normal;
    font-weight: 300;
}

.mat-form-field-label {
    color: $color-main;
    font-family: $font-family !important;
    font-size: $font-size-normal !important;
    font-weight: 400 !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: $normal-gap 0;
    color: $color-main;
}

div.mat-select-arrow,
span.mat-select-value-text,
.mat-form-field.mat-focused .mat-form-field-label {
    color: $color-main;
}

.mat-select-min-line {
    font-size: $font-size-normal;
    font-weight: 400;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
}

.field-required {
    color: $color-alarm;
}

.form-group__items {
    display: flex;
    justify-content: space-between;

    > div {
        flex: 1;
    }

    > div + div {
        padding-left: $normal-gap;
    }
}
