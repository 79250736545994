@import 'projects/admin/src/styles/partials/variables';

.tabs {
  display: flex;
  height: $tabs-height;
  align-items: center;
  text-align: center;
  padding-bottom: $normal-gap;

  .tab {
    flex: 1;
    padding: $normal-gap 0;
    background-color: transparent;
    font-size: $font-size-normal;
    font-weight: 600;
    border-bottom: 1px solid $color-of-content;

    &__link {
      font-size: $font-size-normal;
      color: $color-main !important;
    }

    &--active {
      background-color: $color-of-content;
      border-top-right-radius: $br-big;
      border-top-left-radius: $br-big;
    }
  }
}
