@use 'sass:math';
@import 'fonts';
@import 'projects/admin/src/styles/partials/variables';

html {
    font-size: 18px;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-family, sans-serif !important;
    color: $color-main !important;
    font-size: $font-size-big !important;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: contain;
    user-select: none;
    overflow: hidden;
    background-color: #25282e;
}

html, body {
    scrollbar-width: thin;
}

* {
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: $color-tone_content_1;
    border-radius: 9em;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}

*, *::before, *::after {
    box-sizing: border-box;
}

canvas {
    width: 100% !important;
    height: 100% !important;
}

a {
    font-size: $font-size-small;
    font-weight: 600;
    color: $color-primary;
    outline: none;
    text-decoration: none;
}

p {
    margin-top: math.div($small-gap, 2);
    margin-bottom: math.div($small-gap, 2);
}

.nav-link, .sidenav-link, .widget-simple a {
    color: $color-main;
}

.iterra-wide {
    width: 100%;
}

ui-location-info {
    min-width: 0;
}

div.search {
    div.float-panel__content {
        padding: 0;
    }

    div.float-panel__pull::before {
        background-color: $color-main;
    }
}

.empty {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 $normal-gap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $font-size-small;
    color: $color-tone_content_light;
}


.cdk-virtual-scroll-content-wrapper {
    padding-bottom: $normal-gap;
    border-top: 1px solid $color-tone_content_1;
    border-bottom: 1px solid $color-tone_content_1;
}

div.search {
    .float-panel__header {
        flex: 0;
    }

    .float-panel__content {
        flex: 1;
    }
}

.simple-title {
    font-size: $font-size-normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 135%;
    letter-spacing: 0.1em;
}

.button-add {
    position: absolute;
    z-index: 3;
    right: $normal-gap*3;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $color-main;
    }

    i {
        position: fixed;
        bottom: $footer-height-ios + $small-gap;
        transform: translateX(-50%);
        display: block;
        border-radius: 50%;
        padding: 0;
        width: 50px;
        height: 50px;
        background-color: $color-primary;
    }
}
