@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
html {
  font-size: 18px;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif, sans-serif !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: contain;
  user-select: none;
  overflow: hidden;
  background-color: #25282e;
}

html, body {
  scrollbar-width: thin;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 9em;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

*, *::before, *::after {
  box-sizing: border-box;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

a {
  font-size: 0.777rem;
  font-weight: 600;
  color: #236eff;
  outline: none;
  text-decoration: none;
}

p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.nav-link, .sidenav-link, .widget-simple a {
  color: #ffffff;
}

.iterra-wide {
  width: 100%;
}

ui-location-info {
  min-width: 0;
}

div.search div.float-panel__content {
  padding: 0;
}
div.search div.float-panel__pull::before {
  background-color: #ffffff;
}

.empty {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.777rem;
  color: #8c8e91;
}

.cdk-virtual-scroll-content-wrapper {
  padding-bottom: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

div.search .float-panel__header {
  flex: 0;
}
div.search .float-panel__content {
  flex: 1;
}

.simple-title {
  font-size: 0.888rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 135%;
  letter-spacing: 0.1em;
}

.button-add {
  position: absolute;
  z-index: 3;
  right: 36px;
}
.button-add svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
}
.button-add i {
  position: fixed;
  bottom: 88px;
  transform: translateX(-50%);
  display: block;
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  background-color: #236eff;
}

.layout-wrapper {
  margin: 0 auto;
}

.layout-wrapper, .layout-container {
  height: 100%;
}

.layout-wrapper, .layout-inner {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  width: 100%;
}

.layout-container {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 0;
  flex-basis: 100%;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
}

.layout-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100% !important;
  left: 0;
  display: none;
  background: #25282e;
  opacity: 0.5;
  cursor: pointer;
}
.layout-expanded .layout-overlay {
  display: block;
}

.layout-expanded body {
  overflow: hidden;
}

.layout-collapsed.layout-offcanvas .layout-sidenav {
  transform: translateX(-100%);
}
.layout-collapsed.layout-offcanvas.layout-reversed .layout-sidenav {
  margin-right: 0;
  transform: translateX(100%);
}

.background-filter {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .layout-expanded .layout-overlay {
    display: block;
  }
}
@media (max-width: 768px) {
  .layout-overlay {
    z-index: 1099;
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 12px;
  background-color: #2f3238;
}
.navbar a {
  padding: 0;
  color: #ffffff;
}
.navbar svg {
  fill: #52565f;
}
.navbar__title {
  padding: 0 12px;
  color: #ffffff;
  font-size: 0.888rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar__link {
  line-height: 1.54;
  padding: 0;
}
.navbar__link i {
  font-size: 120%;
  vertical-align: middle;
}
.navbar__link:hover, .navbar__link:focus {
  text-decoration: none;
  outline: none;
}
.navbar__link > [class*=" ion-"]::before {
  vertical-align: baseline;
  line-height: 0.8695em;
  color: #ffffff;
}
.navbar__link--active svg {
  fill: #ffffff;
}
.navbar__nav {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  margin-right: auto;
  list-style: none;
}
.navbar__nav .ion::before, .navbar__nav svg {
  width: 32px;
  height: 32px;
}

[class^=ion-]::before, [class*=" ion-"]::before {
  font-size: 1.8em;
}

.guest {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.navbar__link--active {
  position: relative;
}
.navbar__link--active span.connect {
  display: block;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #06f355;
}

.sidenav {
  display: flex;
  background: transparent;
}

.sidenav-item .sidenav-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}

.sidenav-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 12px;
  font-size: 1rem;
}

.sidenav-vertical .sidenav-item .sidenav-link,
.sidenav-vertical .sidenav-header,
.sidenav-vertical .sidenav-block {
  padding: 0.75rem 2rem;
}

.sidenav-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding-top: 48px;
}

.sidenav-item {
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidenav-vertical {
  width: 15.625rem;
  overflow: hidden;
  flex-direction: column;
  color: #8c8e91;
}
.sidenav-vertical .sidenav-inner > .sidenav-item {
  margin: 0.0625rem 0;
}
.sidenav-vertical .sidenav-icon {
  width: 1.75rem;
  font-size: 100%;
}

.sidenav-vertical,
.sidenav-vertical .sidenav-block,
.sidenav-vertical .sidenav-inner > .sidenav-item,
.sidenav-vertical .sidenav-inner > .sidenav-header {
  width: 15.625rem;
}

.layout-expanded .layout-sidenav {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in-out;
}
.layout-sidenav .sidenav-vertical {
  align-items: flex-start;
  height: 100%;
}

sidenav-router-link {
  font-size: 0.777rem;
}
sidenav-router-link svg {
  margin-right: 12px;
}

.active {
  width: 100%;
  background-color: #236eff;
}

@media (max-width: 768px) {
  .layout-sidenav-100vh .layout-sidenav, .layout-sidenav-100vh .layout-overlay {
    height: 100vh;
  }
}
@media (max-width: 768px) {
  .layout-sidenav-100vh .layout-sidenav, .layout-sidenav-100vh .layout-overlay {
    height: 100vh;
  }
}
@media (max-width: 768px) {
  .layout-sidenav {
    z-index: 1100;
  }

  .layout-sidenav {
    position: fixed !important;
    top: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transform: translate3d(-100%, 0, 0);
    will-change: transform, -webkit-transform;
    flex: 1 0 auto;
    background-color: #2f3238;
  }
}
.iterra-auth {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iterra-auth ui-logo {
  padding-bottom: 24px;
  text-align: center;
}
.iterra-auth > * {
  width: 100%;
}

.not-call {
  padding: 8px 0;
}

.iterra-auth {
  max-width: 1024px;
  margin: 0 auto;
}

#toast-container > div {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.toast-progress {
  position: absolute;
  top: 0;
  bottom: auto;
  height: 0.1875rem;
  opacity: 0.15;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  filter: alpha(opacity=15);
}

.toast-close-button {
  position: absolute;
  top: 0;
  color: inherit;
  text-shadow: none;
  right: 0.25rem;
}

#toast-container .ngx-toastr .toast-title::before {
  position: absolute;
  content: "";
  top: 14px;
  left: 12px;
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center center;
}

#toast-container .toast-info .toast-title::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z'/%3E%3Cpath fill='%23FFFFFF' d='M277 360h-42V235h42v125zm0-166h-42v-42h42v42z'/%3E%3C/svg%3E");
}

#toast-container .toast-warning::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.4414 15.9532L15.4361 3.97762C14.7328 2.7584 13.422 2.00166 12.0043 2C10.5866 1.99834 9.27528 2.75196 8.56324 3.97767L1.5553 15.9576C0.828629 17.1795 0.81219 18.7105 1.51605 19.9592C2.2205 21.2089 3.53954 21.9866 4.97427 21.9983L19.0121 21.9983C20.4618 21.9842 21.7783 21.2089 22.4827 19.9603C23.1862 18.7132 23.1704 17.1856 22.4414 15.9532ZM3.27796 16.9737L10.2911 4.98491C10.6462 4.37354 11.2976 3.99918 12.002 4C12.7063 4.00083 13.3574 4.37673 13.7067 4.98222L20.7176 16.9672C21.0851 17.5885 21.0929 18.3533 20.7407 18.9777C20.388 19.603 19.7286 19.9913 19.0024 19.9984L4.98241 19.9983C4.2718 19.9925 3.6111 19.6029 3.25831 18.9771C2.90591 18.3519 2.91414 17.5855 3.27796 16.9737ZM12.0002 17.9983C12.5527 17.9983 13.0005 17.5506 13.0005 16.9983C13.0005 16.4461 12.5527 15.9983 12.0002 15.9983C11.4477 15.9983 10.9999 16.4461 10.9999 16.9983C10.9999 17.5506 11.4477 17.9983 12.0002 17.9983ZM13.0035 7.99835H11.0028V14.9983H13.0035V7.99835Z' fill='%236B5911'/%3e%3c/svg%3e");
}

#toast-container .toast-error::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5563 1L23 7.44365V16.5563L16.5563 23H7.44365L1 16.5563V7.44365L7.44365 1H16.5563ZM15.7279 3H8.27208L3 8.27208V15.7279L8.27208 21H15.7279L21 15.7279V8.27208L15.7279 3ZM8.70711 16.7071L12 13.4142L15.2929 16.7071L16.7071 15.2929L13.4142 12L16.7071 8.70711L15.2929 7.29289L12 10.5858L8.70711 7.29289L7.29289 8.70711L10.5858 12L7.29289 15.2929L8.70711 16.7071Z' fill='white'/%3e%3c/svg%3e");
}

#toast-container .toast-success::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z' fill='white'/%3e%3c/svg%3e");
}

#toast-container .toast-close-button {
  font-weight: 600;
}
#toast-container > .toast-success {
  background-color: #02bc77;
  color: #ffffff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z' fill='white'/%3e%3c/svg%3e");
}
#toast-container > .toast-error {
  background-color: #f43a3a;
  color: #ffffff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5563 1L23 7.44365V16.5563L16.5563 23H7.44365L1 16.5563V7.44365L7.44365 1H16.5563ZM15.7279 3H8.27208L3 8.27208V15.7279L8.27208 21H15.7279L21 15.7279V8.27208L15.7279 3ZM8.70711 16.7071L12 13.4142L15.2929 16.7071L16.7071 15.2929L13.4142 12L16.7071 8.70711L15.2929 7.29289L12 10.5858L8.70711 7.29289L7.29289 8.70711L10.5858 12L7.29289 15.2929L8.70711 16.7071Z' fill='white'/%3e%3c/svg%3e");
}
#toast-container > .toast-info {
  background-color: #236eff;
  color: #ffffff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z'/%3E%3Cpath fill='%23FFFFFF' d='M277 360h-42V235h42v125zm0-166h-42v-42h42v42z'/%3E%3C/svg%3E");
}
#toast-container > .toast-warning {
  background-color: #ffd950;
  color: #6b5911;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.4414 15.9532L15.4361 3.97762C14.7328 2.7584 13.422 2.00166 12.0043 2C10.5866 1.99834 9.27528 2.75196 8.56324 3.97767L1.5553 15.9576C0.828629 17.1795 0.81219 18.7105 1.51605 19.9592C2.2205 21.2089 3.53954 21.9866 4.97427 21.9983L19.0121 21.9983C20.4618 21.9842 21.7783 21.2089 22.4827 19.9603C23.1862 18.7132 23.1704 17.1856 22.4414 15.9532ZM3.27796 16.9737L10.2911 4.98491C10.6462 4.37354 11.2976 3.99918 12.002 4C12.7063 4.00083 13.3574 4.37673 13.7067 4.98222L20.7176 16.9672C21.0851 17.5885 21.0929 18.3533 20.7407 18.9777C20.388 19.603 19.7286 19.9913 19.0024 19.9984L4.98241 19.9983C4.2718 19.9925 3.6111 19.6029 3.25831 18.9771C2.90591 18.3519 2.91414 17.5855 3.27796 16.9737ZM12.0002 17.9983C12.5527 17.9983 13.0005 17.5506 13.0005 16.9983C13.0005 16.4461 12.5527 15.9983 12.0002 15.9983C11.4477 15.9983 10.9999 16.4461 10.9999 16.9983C10.9999 17.5506 11.4477 17.9983 12.0002 17.9983ZM13.0035 7.99835H11.0028V14.9983H13.0035V7.99835Z' fill='%236B5911'/%3e%3c/svg%3e");
}
#toast-container .ngx-toastr + .ngx-toastr {
  margin-top: 12px;
}

#toast-container > .widget-chat-info {
  padding: 12px;
  margin-top: 12px;
  border-radius: 5px;
  background-color: #2f3238;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-image: none;
}
#toast-container > .widget-chat-info .toast-message {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 19.8042L10.0868 16H18C19.1046 16 20 15.1046 20 14V2C20 0.89543 19.1046 0 18 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H4V19.8042ZM9.5132 14L6 16.1958V14H2V2H18V14H9.5132ZM5 11V9H12V11H5ZM5 5V7H14V5H5Z' fill='white'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 28px;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 65px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-title {
  font-size: 0.888rem;
  font-weight: 600;
}

.toast-message {
  font-size: 0.777rem;
  font-weight: normal;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  position: fixed;
  z-index: 1000;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  padding: 12px 12px 12px 48px;
  border-radius: 5px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  pointer-events: auto;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control {
  width: 100%;
}

.invalid-feedback {
  display: block !important;
  color: #f43a3a !important;
  font-size: 0.777rem;
  font-weight: 400;
}

.ng-touched.ng-valid:not(form) {
  border-color: #02bc77;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

div.form-group {
  width: 100%;
  margin-bottom: 0;
  padding: 8px;
}
div.form-group--top-border {
  margin-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
div.form-group__text {
  font-size: 0.777rem;
  color: #a8a9ab;
}
div.form-group__text + div.form-group__text {
  padding-top: 16px;
}

.title-form-group {
  margin-bottom: 8px;
  font-size: 0.888rem;
  font-weight: 600;
}

.form-control {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 0.777rem;
  font-weight: 400;
  line-height: 1.54;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.form-control {
  background: #2f3238;
  color: #ffffff;
}

option {
  padding: 12px 0;
}

button, input {
  overflow: visible;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: "Open Sans", sans-serif, sans-serif;
  font-size: 0.888rem;
  line-height: inherit;
  outline: none;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:disabled {
  filter: brightness(0.7);
}

.btn {
  height: 48px;
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 12px;
  font-size: 0.888rem;
  line-height: 1.54;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  border-color: transparent;
  background: #236eff;
  color: #ffffff;
}

.btn-calm {
  background: #a8a9ab;
  color: #ffffff;
}

.btn-alarm {
  background: #f43a3a;
  color: #ffffff;
}

.btn-default {
  border-color: #ffffff;
  color: #ffffff;
}

.btn-secondary {
  border-color: transparent;
  background: #8897aa;
  color: #ffffff;
}

.btn-outline-secondary {
  border-color: #8897aa;
  background: transparent;
  color: #ffffff;
}

.btn-greater {
  padding-top: 0.67rem;
  padding-bottom: 0.67rem;
}

.btn-active {
  background: #2cb573;
  color: #ffffff;
}

.checkbox {
  margin-bottom: 16px;
}
.checkbox label {
  margin-bottom: 0;
  font-size: 0.888rem;
  font-weight: normal;
}
.checkbox input[type=checkbox] {
  height: 0;
  width: 0;
  position: absolute;
  left: -12px;
}
.checkbox input[type=checkbox] + label {
  position: relative;
  display: flex;
  align-items: center;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox input[type=checkbox] + label > i {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #52565f;
  border-radius: 3px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox input[type=checkbox]:checked + label > i {
  border: 12px solid #236eff;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox input[type=checkbox]:checked + label > i:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4715 13.5286L16.6667 7.33337L17.6096 8.27618L10.4715 15.4143L6.66675 11.6095L7.60956 10.6667L10.4715 13.5286Z' fill='white'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio + label {
  display: flex;
  user-select: none;
  font-size: 0.888rem;
  font-weight: 400;
}

.custom-radio + label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #52565f;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.05);
}

.custom-radio:checked + label::before {
  border-color: #236eff;
  background-color: #236eff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio:disabled + label::before {
  background-color: #e9ecef;
}

font {
  font-size: 0.888rem;
  font-weight: 300;
}

.mat-form-field-label {
  color: #ffffff;
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.888rem !important;
  font-weight: 400 !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 12px 0;
  color: #ffffff;
}

div.mat-select-arrow,
span.mat-select-value-text,
.mat-form-field.mat-focused .mat-form-field-label {
  color: #ffffff;
}

.mat-select-min-line {
  font-size: 0.888rem;
  font-weight: 400;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.field-required {
  color: #f43a3a;
}

.form-group__items {
  display: flex;
  justify-content: space-between;
}
.form-group__items > div {
  flex: 1;
}
.form-group__items > div + div {
  padding-left: 12px;
}

.notifications {
  height: 100%;
  overflow: auto;
}

.toast {
  max-width: 100%;
}

.bg-dark.bs4-toast {
  color: #fff;
  background-color: rgba(37, 40, 46, 0.9) !important;
}

.bs4-toast[class^=bg-], .bs4-toast[class*=" bg-"] {
  border: none;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  color: #474d58;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.06);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  border-radius: 0.25rem;
}

.bg-dark.bs4-toast .toast-header {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.toast-header {
  background: #8c8e91;
  border-color: #2f3238;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #474d58;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  background: #2f3238;
  padding: 0.75rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 24px;
  margin: 0 16px;
  text-align: center;
}
.modal-body img {
  padding-bottom: 24px;
}
.modal-body__title {
  padding-bottom: 12px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}
.modal-body__text {
  padding-bottom: 24px;
  font-size: 0.888rem;
  font-weight: normal;
}
.modal-body .btn {
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding: 0.7rem 1.125rem;
}
.modal-body .btn + .btn {
  margin-top: 16px;
}
.modal-body .btn-danger {
  background-color: #f43a3a;
  border-color: #f43a3a;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: 100%;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
}
.modal-dialog-scrollable .modal-content {
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
}
.modal-dialog-centered::before {
  display: block;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #2f3238;
  background-clip: padding-box;
  border: 1px solid rgba(47, 50, 56, 0.2);
  outline: 0;
  padding: 8px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100vw;
  height: 100vh;
  background-color: #2f3238;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: none !important;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 8px;
  border-bottom: 1px solid #2f3238;
}
.modal-header .close {
  padding: 8px 8px;
  margin: -8px -8px -8px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid #2f3238;
}
.modal-footer > * {
  margin: 8px;
}
.modal-footer button {
  flex: 1;
  min-width: 200px;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.mapbox-map > *, .map-tag {
  font-family: "Open Sans", sans-serif !important;
}

div.header div.widget-header {
  width: 100%;
  margin: auto;
  background: transparent;
}

.playlist .float-panel__header, .playlists .float-panel__header, .widget-list .float-panel__header {
  padding: 0 !important;
  background-color: #2f3238 !important;
}
.playlist .float-panel__content, .playlists .float-panel__content, .widget-list .float-panel__content {
  padding: 0 !important;
}

.widget-tile--square > div {
  width: 152px !important;
  height: 178px !important;
}
.widget-tile--rectangle > div {
  width: 100% !important;
  height: 100px !important;
}

.tabs {
  display: flex;
  height: 60px;
  align-items: center;
  text-align: center;
  padding-bottom: 12px;
}
.tabs .tab {
  flex: 1;
  padding: 12px 0;
  background-color: transparent;
  font-size: 0.888rem;
  font-weight: 600;
  border-bottom: 1px solid #2f3238;
}
.tabs .tab__link {
  font-size: 0.888rem;
  color: #ffffff !important;
}
.tabs .tab--active {
  background-color: #2f3238;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.emoji-mart-dark,
.emoji-mart-dark .emoji-mart-category-label span,
.emoji-mart-dark .emoji-mart-search input {
  font-family: "Open Sans", sans-serif;
  background-color: #2f3238;
}

.emoji-mart-dark {
  width: 100% !important;
}

.emoji-mart-preview {
  display: none;
}